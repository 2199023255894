import { WorkspaceAction } from "~/layers/admin/modules/auth/runtime/types/api";

export default defineNuxtRouteMiddleware((to) => {
  const { loggedIn, context } = useAuth();
  if (!loggedIn.value) {
    return;
  }

  const workspaceActions =
    context.value?.workspace?.status?.workspace_actions || [];

  if (!workspaceActions.includes(WorkspaceAction.SELECT_PLAN)) {
    return navigateTo("/");
  }
});
